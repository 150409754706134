import { ActionsCreator } from '@/store/utils';

export default ActionsCreator([
  //================================================
  // Authentication
  //================================================
  {
    name: 'register',
    mutation: 'REGISTER',
  },
  {
    name: 'sendValidationMail',
    mutation: 'SEND_VALIDATION_MAIL',
  },
  {
    name: 'getValidationMailStatus',
    mutation: 'GET_VALIDATION_MAIL_STATUS',
  },
  //================================================
  // Password Reset
  //================================================
  {
    name: 'resetPasswordWithOld',
    mutation: 'RESET_PASSWORD_WITH_OLD',
  },
  {
    name: 'resetPasswordWithToken',
    mutation: 'RESET_PASSWORD_WITH_TOKEN',
  },
  {
    name: 'sendResetPasswordTokenEmail',
    mutation: 'SEND_RESET_PASSWORD_TOKEN_EMAIL',
  },
  //================================================
  // Feed
  //================================================
  {
    name: 'getFeed',
    mutation: 'GET_FEED',
  },
  {
    name: 'countNewFeed',
    mutation: 'COUNT_NEW_FEED',
  },

  //================================================
  // Industry
  //================================================
  {
    name: 'getIndustries',
    mutation: 'GET_INDUSTRY',
  },
  {
    name: 'followIndustryToggle',
    mutation: 'FOLLOW_INDUSTRY_TOGGLE',
  },

  //================================================
  // Messages
  //================================================
  {
    name: 'getMessageNotificationsAll',
    mutation: 'GET_MESSAGE_NOTIFICATIONS_ALL',
  },
  {
    name: 'markAsReadMessageNotificationsAll',
    mutation: 'MARK_AS_READ_MESSAGE_NOTIFICATIONS_ALL',
  },
  {
    name: 'removeNotification',
    mutation: 'REMOVE_NOTIFICATION',
  },

  //================================================
  // News
  //================================================
  {
    name: 'getNews',
    mutation: 'GET_NEWS',
  },
  {
    name: 'getCryptoNews',
    mutation: 'GET_CRYPTO_NEWS',
  },
  {
    name: 'getNewsHeadlines',
    mutation: 'GET_NEWS_HEADLINES',
  },
  {
    name: 'getCompanyHeadlines',
    mutation: 'GET_COMPANY_HEADLINES',
  },
  {
    name: 'getMoreCompanyHeadlines',
    mutation: 'GET_MORE_COMPANY_HEADLINES',
  },
  //================================================
  // Widgets
  //================================================

  {
    name: 'getTrendingTickers',
    mutation: 'GET_TRENDING_TICKERS',
  },

  {
    name: 'getTrendingIndices',
    mutation: 'GET_TRENDING_INDICES',
  },
  {
    name: 'getAllTrendingIndices',
    mutation: 'GET_ALL_TRENDING_INDICES',
  },
  {
    name: 'getTrendingCryptos',
    mutation: 'GET_TRENDING_CRYPTOS',
  },
  {
    name: 'getPopularCryptos',
    mutation: 'GET_POPULAR_CRYPTOS',
  },
  {
    name: 'getSimilarCryptos',
    mutation: 'GET_SIMILAR_CRYPTOS',
  },

  //================================================
  // Profile
  //================================================
  {
    name: 'getProfileById',
    mutation: 'GET_PROFILE_BY_ID',
  },
  {
    name: 'getSymbolProfileById',
    mutation: 'GET_SYMBOL_BY_ID',
  },
  {
    name: 'createProfile',
    mutation: 'CREATE_PROFILE',
  },
  {
    name: 'updateProfile',
    mutation: 'UPDATE_PROFILE',
  },
  {
    name: 'uploadProfileAvatar',
    mutation: 'UPLOAD_PROFILE_AVATAR',
  },
  {
    name: 'uploadProfileHeader',
    mutation: 'UPLOAD_PROFILE_HEADER',
  },
  {
    name: 'validateProfileProfilename',
    mutation: 'VALIDATE_PROFILE_USERNAME',
  },
  {
    name: 'getProfileIdFromName',
    mutation: 'GET_PROFILE_ID_FROM_NAME',
  },
  {
    name: 'publicValidateProfileProfilename',
    mutation: 'PUBLIC_VALIDATE_PROFILE_USERNAME',
  },
  {
    name: 'publicValidateUserEmail',
    mutation: 'PUBLIC_VALIDATE_USER_EMAIL',
  },
  {
    name: 'publicGetUserFromProfilename',
    mutation: 'PUBLIC_GET_USER_FROM_PROFILENAME',
  },
  {
    name: 'followProfile',
    mutation: 'FOLLOW_PROFILE',
  },
  {
    name: 'followStockChange',
    mutation: 'FOLLOW_STOCK_CHANGE',
  },
  {
    name: 'followedIdustries',
    mutation: 'GET_FOLLOWED_INDUSTRIES',
  },
  {
    name: 'rateProfile',
    mutation: 'RATE_PROFILE',
  },
  {
    name: 'getProfileFollowers',
    mutation: 'GET_PROFILE_FOLLOWERS',
  },
  {
    name: 'getProfileFollowed',
    mutation: 'GET_PROFILE_FOLLOWED',
  },
  {
    name: 'setWTPmodalSeen',
    mutation: 'WTP_MODAL_SEEN',
  },
  {
    name: 'deleteProfile',
    mutation: 'DELETE_PROFILE',
  },
  {
    name: 'reportProfile',
    mutation: 'REPORT_PROFILE',
  },
  {
    name: 'getReports',
    mutation: 'GET_REPORTS',
  },
  {
    name: 'blockProfile',
    mutation: 'BLOCK_PROFILE',
  },
  {
    name: 'unblockProfile',
    mutation: 'UNBLOCK_PROFILE',
  },
  {
    name: 'getBlocks',
    mutation: 'GET_BLOCKS',
  },

  //================================================
  // Users
  //================================================
  {
    name: 'updateUsersMe',
    mutation: 'UPDATE_USERS_ME',
  },
  {
    name: 'patchUserBirthdate',
    mutation: 'PATCH_USER_BIRTHDATE',
  },
  {
    name: 'deleteUser',
    mutation: 'DELETE_USER',
  },

  //================================================
  // Search
  //================================================
  {
    name: 'search',
    mutation: 'SEARCH',
  },
  { name: 'getSearchHistory', mutation: 'SEARCH_HISTORY' },

  //================================================
  // Portfolio
  //================================================
  {
    name: 'createPortfolio',
    mutation: 'CREATE_PORTFOLIO',
  },
  {
    name: 'getPortfolioTopGainers',
    mutation: 'GET_PORTFOLIO_TOP_GAINERS',
  },
  {
    name: 'getPortfolioTopGainersFollowed',
    mutation: 'GET_PORTFOLIO_TOP_GAINERS_FOLLOWED',
  },
  {
    name: 'portfolioSearch',
    mutation: 'PORTFOLIO_SEARCH',
  },
  {
    name: 'getPortfolioComparisonGraphInfo',
    mutation: 'GET_PORTFOLIO_COMPARISON_GRAPH_INFO',
  },
  {
    name: 'orderPortfolios',
    mutation: 'ORDER_PORTFOLIOS',
  },
  {
    name: 'orderPortfolios',
    mutation: 'ORDER_PORTFOLIOS',
  },
  {
    name: 'getPortfolioById',
    mutation: 'GET_PORTFOLIO_BY_ID',
  },
  {
    name: 'getCompanySymbols',
    mutation: 'GET_COMPANY_SYMBOLS',
  },
  {
    name: 'getMultipleCompanySymbols',
    mutation: 'GET_MULTIPLE_COMPANY_SYMBOLS',
  },
  {
    name: 'getCryptoSymbols',
    mutation: 'GET_CRYPTO_SYMBOLS',
  },
  {
    name: 'getPrimaryTickerFundamentals',
    mutation: 'GET_PRIMARY_TICKER_FUNDAMENTALS',
  },
  {
    name: 'getTickerFundamentals',
    mutation: 'GET_TICKER_FUNDAMENTALS',
  },
  {
    name: 'removePortfolio',
    mutation: 'REMOVE_PORTFOLIO',
  },
  {
    name: 'likePortfolio',
    mutation: 'LIKE_PORTFOLIO',
  },
  {
    name: 'toggleCompleteView',
    mutation: 'TOGGLE_COMPLETE_VIEW',
  },
  {
    name: 'updatePortfolio',
    mutation: 'UPDATE_PORTFOLIO',
  },
  {
    name: 'getPortfolioGraph',
    mutation: 'GET_PORTFOLIO_GRAPH',
  },
  {
    name: 'getMyPortfolios',
    mutation: 'GET_MY_PORTFOLIOS',
  },
  {
    name: 'getProfilePortfolios',
    mutation: 'GET_PROFILE_PORTFOLIOS',
  },
  {
    name: 'getTopInvestors',
    mutation: 'GET_TOP_INVESTORS',
  },
  {
    name: 'createOperation',
    mutation: 'CREATE_OPERATION',
  },
  {
    name: 'createCashOperation',
    mutation: 'CREATE_CASH_OPERATION',
  },
  {
    name: 'addPortfolioItem',
    mutation: 'ADD_PORTFOLIO_ITEM',
  },
  {
    name: 'removePortfolioItem',
    mutation: 'REMOVE_PORTFOLIO_ITEM',
  },
  {
    name: 'removePortfolioCashItem',
    mutation: 'REMOVE_PORTFOLIO_CASH_ITEM',
  },
  {
    name: 'getCryptoTickerStockRangeValue',
    mutation: 'GET_CRYPTO_TICKER_VALUE',
  },
  {
    name: 'getTickerStockRangeValue',
    mutation: 'GET_TICKER_VALUE',
  },
  {
    name: 'getTickerStockRangeWithOverall',
    mutation: 'GET_TICKER_VALUE_WITH_OVERALL',
  },
  {
    name: 'getMultipleTickerStockRangeValue',
    mutation: 'GET_MULTIPLE_TICKER_STOCK_VALUE',
  },
  {
    name: 'getMultipleTickerStockRangeValueWithOverall',
    mutation: 'GET_MULTIPLE_TICKER_STOCK_VALUE_WITH_OVERALL',
  },
  {
    name: 'getTeamPortfolioProfiles',
    mutation: 'GET_TEAM_PORTFOLIO_PROFILES',
  },
  {
    name: 'updateTeamPortfolioProfile',
    mutation: 'UPDATE_TEAM_PORTFOLIO_PROFILE',
  },
  {
    name: 'deleteTeamPortfolioProfile',
    mutation: 'DELETE_TEAM_PORTFOLIO_PROFILE',
  },
  {
    name: 'createTeamPortfolioProfile',
    mutation: 'CREATE_TEAM_PORTFOLIO_PROFILE',
  },
  {
    name: 'getCryptoTickerStockValue',
    mutation: 'GET_CRYPTO_TICKER_STOCK_VALUE',
  },
  {
    name: 'getTickerStockValue',
    mutation: 'GET_TICKER_STOCK_VALUE',
  },
  {
    name: 'getSimilarTickers',
    mutation: 'GET_SIMILAR_TICKERS',
  },
  {
    name: 'getMyFavoritePortfolios',
    mutation: 'GET_MY_FAVORITE_PORTFOLIOS',
  },
  {
    name: 'getFavoritePortfolio',
    mutation: 'GET_FAVORITE_PORTFOLIO',
  },
  {
    name: 'removeFavoritePortfolio',
    mutation: 'REMOVE_FAVORITE_PORTFOLIO',
  },
  {
    name: 'favoritePortfolio',
    mutation: 'FAVORITE_PORTFOLIO',
  },
  {
    name: 'getPortfolioComments',
    mutation: 'GET_PORTFOLIO_COMMENTS',
  },

  //================================================
  // Post
  //================================================
  {
    name: 'getPostById',
    mutation: 'GET_POST_BY_ID',
  },
  {
    name: 'getPosts',
    mutation: 'GET_POSTS',
  },
  {
    name: 'getPostReplies',
    mutation: 'GET_POST_REPLIES',
  },
  {
    name: 'createPost',
    mutation: 'CREATE_POST',
  },
  {
    name: 'reportPost',
    mutation: 'REPORT_POST',
  },
  {
    name: 'uploadPostImage',
    mutation: 'UPLOAD_POST_IMAGE',
  },
  {
    name: 'likePost',
    mutation: 'LIKE_POST',
  },
  {
    name: 'favoritePost',
    mutation: 'FAVORITE_POST',
  },
  {
    name: 'getFavoritesPost',
    mutation: 'GET_FAVORITES_POST',
  },
  {
    name: 'getProfilePosts',
    mutation: 'GET_PROFILE_POSTS',
  },
  {
    name: 'getPoll',
    mutation: 'GET_POLL',
  },
  {
    name: 'getPollResults',
    mutation: 'GET_POLL_RESULTS',
  },
  {
    name: 'createPoll',
    mutation: 'CREATE_POLL',
  },
  {
    name: 'votePoll',
    mutation: 'VOTE_POLL',
  },

  //================================================
  // Notifications
  //================================================
  {
    name: 'getNotifications',
    mutation: 'GET_NOTIFICATIONS',
  },
  {
    name: 'handleReadAllNotification',
    mutation: 'HANDLE_READ_ALL_NOTIFICATION',
  },
  {
    name: 'handleReadMultipleNotifications',
    mutation: 'HANDLE_READ_MULTIPLE_NOTIFICATIONS',
  },
  {
    name: 'handleReadNotification',
    mutation: 'HANDLE_READ_NOTIFICATION',
  },
  {
    name: 'removeNotification',
    mutation: 'DELETE_NOTIFICATION',
  },
  {
    name: 'notificationsCount',
    mutation: 'GET_NOTIFICATIONS_COUNT',
  },

  //================================================
  // Onboarding
  //================================================
  {
    name: 'tutorialNextStep',
    mutation: 'TUTORIAL_NEXT_STEP',
  },
  { name: 'tutorialPreviousStep', mutation: 'TUTORIAL_PREVIOUS_STEP' },
  {
    name: 'completeTutorial',
    mutation: 'COMPLETE_TUTORIAL',
  },
  {
    name: 'tipsNextStep',
    mutation: 'TIPS_NEXT_STEP',
  },
  {
    name: 'completeTips',
    mutation: 'COMPLETE_TIPS',
  },
  {
    name: 'getUserOnboardingQuestions',
    mutation: 'GET_USER_ONBOARDING_QUESTIONS',
  },
  {
    name: 'getUserOnboardingAnswersByProfileId',
    mutation: 'GET_USER_ONBOARDING_ANSWERS',
  },
  {
    name: 'saveUserOnboardingAnswers',
    mutation: 'SAVE_USER_ONBOARDING_ANSWERS',
  },

  //================================================
  // Recommendations
  //================================================
  {
    name: 'getRecommendedProfiles',
    mutation: 'GET_RECOMMENDED_PROFILES',
  },
  {
    name: 'getRecommendedCompanies',
    mutation: 'GET_RECOMMENDED_COMPANIES',
  },
  {
    name: 'getMutualFollowsRecommendation',
    mutation: 'GET_MUTUAL_FOLLOWS_RECOMMENDATION',
  },
  {
    name: 'getMutualIndustriesRecommendation',
    mutation: 'GET_MUTUAL_INDUSTRIES_RECOMMENDATION',
  },
  {
    name: 'getSimilarCompaniesRecommendation',
    mutation: 'GET_SIMILAR_COMPANIES_RECOMMENDATION',
  },
  {
    name: 'getCompanyFollowersRecommendation',
    mutation: 'GET_COMPANY_FOLLOWERS_RECOMENDAITON',
  },
  //================================================
  // Languages
  //================================================
  {
    name: 'getNewsSuscribedLanguages',
    mutation: 'GET_NEWS_SUSCRIBED_LANGUAGES',
  },
  //================================================
  // Stocks
  //================================================
  {
    name: 'searchStocks',
    mutation: 'SEARCH_STOCKS',
  },
  //================================================
  // Leagues
  //================================================
  {
    name: 'createAdminLeague',
    mutation: 'CREATE_ADMIN_LEAGUE',
  },
  {
    name: 'searchLeagues',
    mutation: 'SEARCH_LEAGUES',
  },
]);
